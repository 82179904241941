



































import Vue from 'vue';
import TitleProvider from '@/api/TitleProvider';
import ITitle from '@/models/title/ITitle';
import TitleListEntry from './TitleListEntry.vue';
import Loader from '@/components/util/Loader.vue';

enum SortMode {
    ID = 0,
    ABC = 1,
}

interface IData {
    titles: ITitle[];
    loading: boolean;
    error: string;
    sortMode: SortMode;
    asc: boolean;
    search: string;
}

export default Vue.extend({
    components: {
        TitleListEntry,
        Loader,
    },
    data(): IData {
        return {
            titles: [],
            loading: true,
            error: '',
            sortMode: SortMode.ID,
            asc: true,
            search: '',
        };
    },
    mounted() {
        this.load();
    },
    computed: {
        sorted(): ITitle[] {
            let sorter: (a: ITitle, b: ITitle) => number;
            switch (this.sortMode) {
                case SortMode.ID:
                    sorter = (a: ITitle, b: ITitle) => a.id - b.id;
                    break;
                case SortMode.ABC:
                    sorter = (a: ITitle, b: ITitle) => a.name.message.localeCompare(b.name.message, undefined, {
                        sensitivity: 'base',
                        ignorePunctuation: true,
                    });
                    break;
                default:
                    throw new Error();
            }
            
            if (!this.asc) {
                const oldSorter = sorter;
                sorter = (a: ITitle, b: ITitle) => -oldSorter(a, b);
            }
            
            let r = [...this.titles].sort(sorter);
            
            if (this.search) {
                const s = this.search.toLowerCase();
                r = r.filter((v) => v.name.message.toLowerCase().includes(s));
            }
            
            return r;
        }
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                this.titles = [];
                let rr = [];
                let top = 0;
                do {
                    rr = await TitleProvider.listTitles(top);
                    if (rr.length > 0) {
                        top = rr[rr.length - 1].id + 1;
                    }
                    
                    this.titles.push(...(rr.filter((v) => !v.name.message.startsWith('[MID:'))));
                } while (rr.length > 0)
                
            } catch (error) {
                this.error = String(error);
            } finally {
                this.loading = false;
            }
        },
    }
})
