


















































































import ItemsProvider, { CashFilter, ISearchParams, searchParamsToQueryObject } from '@/api/ItemsProvider';
import IItemStub from '@/models/items/IItemStub';
import Vue from 'vue'
import ItemSearchCategorySelector from './ItemSearchCategorySelector.vue';
import ItemSearchPartSelector from './ItemSearchPartSelector.vue';
import ItemSearchTypeSelector from './ItemSearchTypeSelector.vue';

interface GradeFilter {
    normal: boolean;
    magic: boolean;
    rare: boolean;
    epic: boolean;
    unique: boolean;
    legendary: boolean;
    divine: boolean;
    ancient: boolean;
};

interface IFilter {
    minLevel: number;
    maxLevel: number;
    name: string;
    classes: number[];
    types: number[];
    parts: number[];
    categories: number[];
    cash: CashFilter;
    grades: GradeFilter;
    
}

interface IData {
    filter: IFilter;
    loading: boolean;
    error: string;
    results: IItemStub[];
}

function defaultFilter(): IFilter {
    return {
        minLevel: 0,
        maxLevel: 100,
        name: '',
        classes: [],
        types: [],
        parts: [],
        categories: [],
        cash: CashFilter.ALL,
        grades: {
            normal: true,
            magic: true,
            rare: true,
            epic: true,
            unique: true,
            legendary: true,
            divine: true,
            ancient: true,
        }
    }
}

function gradesToArray(filter: IFilter): number[] {
    const ret = [];
    if (filter.grades.normal) {
        ret.push(0);
    }
    if (filter.grades.magic) {
        ret.push(1);
    }
    if (filter.grades.rare) {
        ret.push(2);
    }
    if (filter.grades.epic) {
        ret.push(3);
    }
    if (filter.grades.unique) {
        ret.push(4);
    }
    if (filter.grades.legendary) {
        ret.push(5);
    }
    if (filter.grades.divine) {
        ret.push(6);
    }
    if (filter.grades.ancient) {
        ret.push(7);
    }
    
    if (ret.length === 8) {
        return [];
    }
    
    return ret;
}

function gradeArrayToGrades(arr: number[]): GradeFilter {
    console.log(arr);
    const ret: GradeFilter = {
        normal: false,
        magic: false,
        rare: false,
        epic: false,
        unique: false,
        legendary: false,
        divine: false,
        ancient: false,
    };
    
    const gfKeys: (keyof GradeFilter)[] = ['normal', 'magic', 'rare', 'epic', 'unique', 'legendary', 'divine', 'ancient'];
    
    if (arr.length === 0) {
        for (const k of gfKeys) {
            ret[k] = true;
        }
        
        return ret;
    }
    
    for (const i of arr) {
        const k = gfKeys[i];
        if (k) {
            ret[k] = true;
        }
    }
    
    return ret;
}

export default Vue.extend({
    components: {
        ItemSearchCategorySelector,
        ItemSearchPartSelector,
        ItemSearchTypeSelector,
    },
    data(): IData {
        return {
            filter: defaultFilter(),
            loading: false,
            error: '',
            results: [],
        };
    },
    mounted() {
            this.syncQuery();
    },
    watch: {
        $route() {
            this.syncQuery();
        },
    },
    computed: {
        disabled(): boolean {
            return this.loading || !this.filter.name;
        },
    },
    methods: {
        async search() {
            if (this.disabled) {
                return;
            }

            try {
                this.loading = true;
                const params = {
                    name: this.filter.name,
                    ranks: gradesToArray(this.filter),
                    types: this.filter.types,
                    parts: this.filter.parts,
                    cash: this.filter.cash,
                    categories: this.filter.categories,
                    classes: [],
                    maxLevel: this.filter.maxLevel,
                    minLevel: this.filter.minLevel,
                };
                
                this.setQuery(params);
                
                this.results = (await ItemsProvider.search(params)).items;
            } catch (e) {
                this.error = String(e);
            } finally {
                this.loading = false;
            }
        },
        resetFilter() {
            this.filter = defaultFilter();
            const params = {
                name: this.filter.name,
                ranks: gradesToArray(this.filter),
                types: this.filter.types,
                parts: this.filter.parts,
                cash: this.filter.cash,
                categories: this.filter.categories,
                classes: [],
                maxLevel: this.filter.maxLevel,
                minLevel: this.filter.minLevel,
            };
            
            this.setQuery(params);
        },
        toggleCategory(evt: InputEvent, id: number) {
            const target = evt.target as HTMLInputElement;
            if (target.checked) {
                this.filter.categories.push(id);
            } else {
                const idx = this.filter.categories.findIndex((v) => v === id);
                this.filter.categories.splice(idx, 1);
            }
        },
        syncQuery() {
            const filter = defaultFilter();
            const q = this.$route.query;
            
            function extractNumericArray(v: any): number[] {
                if (v == null || v === '') {
                    return [];
                }
                
                return v.split(',').map(Number);
            }
            
            filter.name = q.name ?? filter.name;
            filter.minLevel = Number(q.lmin ?? filter.minLevel);
            filter.maxLevel = Number(q.lmax ?? filter.maxLevel);
            filter.classes = (q.cls ?? '').split(',').map(Number);
            filter.grades = gradeArrayToGrades(extractNumericArray(q.g));
            filter.categories = extractNumericArray(q.c);
            filter.types = extractNumericArray(q.t);
            filter.parts = extractNumericArray(q.parts);
            filter.cash = (q.cash != null ? Number(q.cash) : CashFilter.ALL);
            
            this.filter = filter;
        },
        setQuery(params: ISearchParams) {
            const query = searchParamsToQueryObject(params);
            const convertedQuery: any = {};
            for (const k in query) {
                const v = (query as any)[k];
                if (v == null || v === '') {
                    continue;
                }
                
                if (k === 'lmin' && v === 0) {
                    continue;
                }
                
                if (k === 'lmax' && v === 100) {
                    continue;
                }
                
                convertedQuery[k] = String(v);
            }
            
            this.$router.replace({
                ...this.$route,
                query: convertedQuery,
            });
        }
    }
})
