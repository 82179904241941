























import { ItemType } from '@/models/items/ItemEnums'
import Vue from 'vue';

interface IData {
    entries: IEntry[];
}

interface IEntry {
    id: ItemType;
    label: string;
    selected: boolean;
}

function friendlyName(s: string): string {
    const split = s.split('_').map((v) => v[0].toUpperCase() + v.substring(1).toLowerCase());
    return split.join(' ');
}

function generateEntries(): IEntry[] {
    const ret: IEntry[] = [];
    for (const key of Object.keys(ItemType)) {
        if (!isNaN(Number(key))) {
            ret.push({
                id: Number(key as any),
                label: friendlyName(ItemType[key as any]),
                selected: true,
            });
        }
    }
    
    return ret;
}

export default Vue.extend({
    
    data(): IData {
        return {
            entries: generateEntries(),
        };
    },
    mounted() {
        this.syncQuery();
    },
    watch: {
        $route() {
            this.syncQuery();
        },
    },
    computed: {
        notAll(): boolean {
            return this.entries.findIndex((v) => !v.selected) !== -1;
        },
    },
    methods: {
        toggle(evt: InputEvent, entry: IEntry) {
            const target = evt.target as HTMLInputElement;
            const selected = target.checked;
            entry.selected = selected;
            this.compute();
        },
        select(set: boolean) {
            this.entries.forEach((v) => v.selected = set);
            this.compute();
        },
        compute() {
            const ret = this.entries.filter((v) => v.selected).map((v) => v.id);
            
            const res = ret.length === this.entries.length ? [] : ret;
            this.$emit('select', res);
        },
        syncQuery() {
            const q = this.$route.query;
            
            function extractNumericArray(v: any): number[] {
                if (v == null || v === '') {
                    return [];
                }
                
                return v.split(',').map(Number);
            }
            
            const rawTypes = extractNumericArray(q.t);
            if (rawTypes.length === 0) {
                this.select(true);
            } else {
                this.select(false);
                for (const e of this.entries) {
                    const idx = rawTypes.findIndex((v) => v === e.id);
                    if (idx !== -1) {
                        e.selected = true;
                    }
                }
                
                this.compute();
            }
        }
    }
})
